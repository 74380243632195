import React, { Component } from "react";
import styles from "./LegCollection.module.scss";
import Leg from "../Leg";
import Button from "../common/Button";
import moment from "moment";

var dwellList = new Map();
class LegCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeLeg: null,
      legs: [],
      updatedLegs: [],
      legRotationTimer: null,
    };
  }

  componentDidMount = () => {
    this.setState({
      legs: this.props.legs,
      updatedLegs: this.props.legs,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      this.props.terminateLegAnimation !== prevProps.terminateLegAnimation &&
      this.props.terminateLegAnimation
    ) {
      clearInterval(this.state.legRotationTimer);
      this.setState({ legRotationTimer: null });
      this.props.setAnimateLegs(false);
    }

    if (this.props.animateLegs !== prevProps.animateLegs) {
      if (this.props.animateLegs) {
        this.toggleRotateThroughLegs();
      } else {
        clearInterval(this.state.legRotationTimer);
        this.setState({ legRotationTimer: null });
      }
    }
  };

  updateTime = (activeLeg, timediffrence) => {
    let legs = [...this.state.legs];
    let updatedLegs = [...this.state.updatedLegs];
    dwellList.set(activeLeg, timediffrence);
    for (var index = 0; index < activeLeg; index++) {
      if (dwellList.get(index) && dwellList.get(index) !== 0) {
        dwellList.set(
          activeLeg,
          dwellList.get(activeLeg) - dwellList.get(index)
        );
      }
    }
    for (var i = 0; i < legs.length; i++) {
      let item = { ...legs[i] };
      let updatedItem = { ...updatedLegs[i] };
      item.dwellTimeActive = false;
      item.affectDestination = false;
      item.timeDifferenceArrival = 0;
      item.timeDifferenceDeparture = 0;

      for (var k = i; k > 0; k--) {
        if (dwellList.get(k) && dwellList.get(k) !== 0) {
          item.dwellTimeActive = true;
          item.affectDestination = false;

          if (k === i && item.mode === "Destination") {
            item.timeDifferenceArrival =
              item.timeDifferenceArrival + dwellList.get(k);
          } else {
            item.timeDifferenceDeparture =
              item.timeDifferenceDeparture + dwellList.get(k);
            item.timeDifferenceArrival =
              item.timeDifferenceArrival + dwellList.get(k);
          }
        }
      }

      updatedItem.departureTime = new Date(
        new Date(item.departureTime).getTime() + item.timeDifferenceDeparture
      );
      updatedItem.arrivalTime = new Date(
        new Date(item.arrivalTime).getTime() + item.timeDifferenceArrival
      );

      if (dwellList.get(i) && dwellList.get(i) !== 0) {
        item.affectDestination = true;
      }
      legs[i] = item;
      updatedLegs[i] = updatedItem;
    }
    this.setState({ legs });
    this.setState({ updatedLegs });
    this.props.onLegUpdateClicked(updatedLegs);
  };

  handleJourneyLegClicked = (leg, boundingBox, activeLeg, isAuto) => {
    if (!isAuto) {
      this.props.setAnimateLegs(false);
      clearInterval(this.state.legRotationTimer);
      this.setState({ legRotationTimer: null });
    }

    this.props.onJourneyLegClicked(leg, boundingBox);
    this.setState({ activeLeg: activeLeg });
    this.setState({ showMapButton: true });
    this.props.setSelectedLegIndex(activeLeg);
  };

  handleLoginOperation = () => {
    this.props.handleLoginOperation();
  };

  handleButtonClick = () => {
    if (this.state.activeLeg !== null) {
      var mapURI = getLegMapUri(this.props.legs[this.state.activeLeg]);
      window.open(mapURI);
    }
  };

  toggleRotateThroughLegs = () => {
    if (this.state.legRotationTimer) {
      clearInterval(this.state.legRotationTimer);
      this.setState({ legRotationTimer: null });
      return;
    }

    if (this.state.activeLeg == null) {
      this.handleJourneyLegClicked(
        this.state.legs[0],
        this.state.legs[0].boundingBox,
        0,
        true
      );
    }

    let counter = this.state.activeLeg + 1;
    const rotationTimer = setInterval(() => {
      const selectedLegIndex = counter % this.state.legs.length;
      if (selectedLegIndex === 0) {
        this.setState({ activeLeg: null });
        this.props.setInitialViewPort();
        clearInterval(this.state.legRotationTimer);
        this.setState({ legRotationTimer: null });
        this.props.setAnimateLegs(false);
      } else {
        this.handleJourneyLegClicked(
          this.state.legs[selectedLegIndex],
          this.state.legs[selectedLegIndex].boundingBox,
          selectedLegIndex,
          true
        );
      }
      counter++;
    }, 3750);
    this.setState({ legRotationTimer: rotationTimer });
  };

  toggleSelection = () => {
    this.props.toggleSelection();
  };

  toggleRequestAssistance = (travelforecastId) => {
    this.props.toggleRequestAssistance(travelforecastId);
  };

  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.active !== this.props.active && nextProps.active === false) {
      this.setState({ activeLeg: null });
    }
  };

  render() {
    const { active } = this.props;
    const { legs } = this.state;

    let previousLegDate;
    let showExtendedDate;

    return (
      <div
        className={`${styles["LegCollection"]} ${
          active ? styles["active"] : styles["inactive"]
        }`}
      >
        <ol className={styles["leg-list"]}>
          {legs.map((leg, index) => {
            const departureDate = moment.parseZone(leg.departureTime).day();
            if (index === 0 || index === legs.length - 1) {
              showExtendedDate = true;
            } else if (previousLegDate && previousLegDate !== departureDate) {
              showExtendedDate = true;
            } else {
              showExtendedDate = false;
            }
            previousLegDate = moment.parseZone(leg.departureTime).day();

            return (
              <Leg
                key={index}
                leg={leg}
                legIndex={index}
                fareDetails={this.props.fares?.find((fare) =>
                  fare.fare_legs.find((fare_leg) => fare_leg == index)
                )}
                legLength={legs.length}
                onUpdateTimeClicked={this.updateTime}
                onJourneyLegClicked={this.handleJourneyLegClicked}
                className={this.state.activeLeg === index ? "active" : ""}
                apiToken={this.props.apiToken}
                toggleSelection={this.toggleSelection}
                showTravelPlan={this.props.showTravelPlan}
                travelForecast={this.props.travelForecast}
                toggleRequestAssistance={this.toggleRequestAssistance}
                auth={this.props.auth}
                showInfoPopup={this.props.showInfoPopup}
                showExtendedDate={showExtendedDate}
                toggleDirectionsModal={this.props.toggleDirectionsModal}
                hasDirections={
                  leg.directions != null && leg.directions.length > 0
                }
                allowEditTime={this.props.allowEditTime}
              />
            );
          })}
          {this.props.enableNavigation && (
            <div
              className={
                this.state.showMapButton
                  ? styles["map-button-show"]
                  : styles["map-button-hide"]
              }
            >
              <Button
                className={styles["Button"]}
                text={"Open journey step in maps"}
                handleButtonClick={this.handleButtonClick}
              />
            </div>
          )}
        </ol>
      </div>
    );
  }
}

function changeMode(mode, isApple) {
  if (isApple) {
    switch (mode) {
      case "Walk":
        return "w";
      case "Drive":
      case "ElectricVehicle":
        return "d";
      case "Bus":
      case "Train":
      case "Tram":
        return "r";
      default:
        return "";
    }
  } else {
    switch (mode) {
      case "Walk":
        return "walking";
      case "Cycle":
        return "bicycling";
      case "CycleStreetsQuietest":
        return "bicycling";
      case "CycleStreetsBalanced":
        return "bicycling";
      case "CycleStreetsFastest":
        return "bicycling";
      case "CycleStreetsDefault":
        return "bicycling";
      case "Drive":
      case "ElectricVehicle":
        return "driving";
      case "Bus":
      case "Train":
      case "Tram":
        return "transit";
      default:
        return "";
    }
  }
}

function getLegMapUri(leg) {
  var baseUri;
  var o = leg.startLocation.latitude.toString();
  var d = leg.endLocation.latitude.toString();
  var origin = o.concat("%2C", leg.startLocation.longitude.toString());
  var destination = d.concat("%2C", leg.endLocation.longitude.toString());

  if (
    navigator.platform.indexOf("iPhone") !== -1 ||
    navigator.platform.indexOf("MacIntel") !== -1 ||
    navigator.platform.indexOf("iPad") !== -1
  ) {
    var modeApple = changeMode(leg.mode, true);
    return (
      "maps://www.google.com/maps/dir/?api=1&saddr=" +
      origin +
      "&daddr=" +
      destination +
      "&dirflg=" +
      modeApple
    );
  } else {
    baseUri = "https://www.google.com/maps/dir/?api=1&";
  }
  var mode = changeMode(leg.mode, false);
  return (
    baseUri +
    "origin=" +
    origin +
    "&destination=" +
    destination +
    "&travelmode=" +
    mode
  );
}

export default LegCollection;
